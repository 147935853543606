<template>
  <v-card v-if="$can('warehouse_list', 'warehouse')">
    <v-card-title
      v-if="$can('warehouse_save', 'warehouse')"
      class="d-flex align-center flex-wrap pb-0"
    >
      <div class="d-flex align-center pb-5">
        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          @click="editItem()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>新增一级仓库</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :hide-default-footer="true"
        :items-per-page="pages.itemsPerPage"
        :server-items-length="pages.serverItemsLength"
        :headers="getHeader()"
        :items="warehouse"
        no-data-text="无数据"
      >
        <template #[`item.name`]="{ item }">
          <span v-text="getName(item)"></span>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="$can('warehouse_view_stock', 'warehouse')"
            text
            dense
            x-small
            color="primary"
            @click="toStockLog(item)"
          >
            查看库存
          </v-btn>
          <v-btn
            v-if="$can('warehouse_save', 'warehouse')"
            text
            dense
            x-small
            color="primary"
            @click="addSubWarehouse(item)"
          >
            添加子仓库
          </v-btn>
          <v-btn
            v-if="$can('warehouse_update', 'warehouse')"
            text
            dense
            x-small
            color="primary"
            @click="editItem(item)"
          >
            编辑
          </v-btn>
          <v-btn
            v-if="$can('warehouse_delete', 'warehouse')"
            text
            dense
            x-small
            color="primary"
            @click="showDeleteDialog(item)"
          >
            删除
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}仓库</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5"
            color="primary"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-form
              ref="editForm"
              lazy-validation
              @submit.prevent="handleFormSubmit"
            >
              <v-card-text class="pa-3">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="name"><span style="color: red">*</span>仓库名称：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="name"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        required
                        counter="25"
                        label="仓库名称"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="showWarehouseMenu"
                      cols="12"
                      md="2"
                    >
                      <label for="parentName">上级仓库：</label>
                    </v-col>
                    <v-col
                      v-if="showWarehouseMenu"
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        v-show="false"
                        id="parentId"
                        v-model="editedItem.parentId"
                      ></v-text-field>
                      <v-text-field
                        id="href"
                        v-model="parentName"
                        :rules="[]"
                        counter="25"
                        label="仓库分类"
                        readonly
                        outlined
                        clearable
                        dense
                        @click="treeDialog = true"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="orderNum">排序：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="orderNum"
                        v-model="editedItem.sortNum"
                        label="排序"
                        type="Number"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="description">描述：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-textarea
                        v-model="editedItem.description"
                        outlined
                        name="input-7-4"
                        label="描述"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>

      <TreeSelect
        v-model="treeDialog"
        :active="active"
        :selected-tree-data="selectedTreeData"
        title="选择上级仓库"
        @getActive="getActive"
      ></TreeSelect>
    </v-navigation-drawer>

    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 是否删除 {{ deleteData.name }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem(id)"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose,
} from '@mdi/js'
import { required } from '@core/utils/validation'

import TreeSelect from '@/components/select/TreeSelect'
import WareHouseApi from '@/api/product/warehouse'

export default {
  name: 'WareHouse',
  components: {
    TreeSelect,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      pages: {
        itemsPerPage: -1,
        serverItemsLength: 0,
      },
      rules: {
        required,
      },
      warehouse: [],
      editDialog: false,
      treeDialog: false,
      iconDialog: false,
      deleteDialog: false,
      editedItem: {},
      defaultItem: {
        parentId: 0,
        sortNum: 1,
        name: '',
        description: '',
      },
      loadings: {
        submitLoading: false,
      },
      selectedTreeData: [],
      parentName: '',
      selectNode: [],
      active: [],
      deleteData: {},
      showWarehouseMenu: false,
      levelFont: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      commitFlag: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '仓库名称', value: 'name' },
        { text: '商品种类', value: 'quantity' },
        { text: '创建时间', value: 'createdTime' },
      ]

      if (this.$can('warehouse_update', 'warehouse')
          || this.$can('warehouse_save', 'warehouse')
          || this.$can('warehouse_delete', 'warehouse')
          || this.$can('warehouse_view_stock', 'warehouse')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    getName(item) {
      let prefix = ''
      for (let index = 0; index < item.level; index++) {
        prefix += '\xa0\xa0\xa0\xa0'
      }

      return `${prefix}${item.name}`
    },
    editItem(item) {
      this.active = []
      if (item) {
        if (item.parentId === 0) {
          this.showWarehouseMenu = false
        } else {
          this.showWarehouseMenu = true
        }

        this.editedItem = item

        // 通过遍历找到items下中被嵌套的数据
        if (item.parentId !== 0) {
          const node = this.findMenuNode(this.editedItem.parentId, this.selectedTreeData)
          this.parentName = node.name
          const obj = {
            id: item.parentId,
          }
          this.active.push(obj)
        }
      } else {
        this.showWarehouseMenu = false
        this.initEditedItem()
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    addSubWarehouse(item) {
      this.initEditedItem()
      this.$nextTick(() => {
        this.$refs.editForm.resetValidation()
        this.editedItem.parentId = item.id
        this.showWarehouseMenu = false
      })
      this.editDialog = true
    },
    deleteItem() {
      WareHouseApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadData()
      })
    },
    getActive(val) {
      this.selectNode = val
      if (val.length > 0) {
        this.parentName = val[0].name
        this.editedItem.parentId = val[0].id
      } else {
        this.parentName = ''
        this.editedItem.parentId = 0
      }
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      this.commitFlag = true
      WareHouseApi.saveOrUpdate(this.editedItem).then(res => {
        this.$toast.success('保存成功')
        this.commitFlag = false
        this.closeDialog()
        this.loadData()
      })
    },
    loadData() {
      if (this.$can('warehouse_list', 'warehouse')) {
        WareHouseApi.listAll().then(res => {
          this.warehouse = res.data.data
          this.pages.serverItemsLength = this.warehouse.length
        })
      }
      if (this.$can('warehouse_edit_data', 'warehouse')) {
        WareHouseApi.getNodeList().then(res => {
          const { data } = res.data
          this.selectedTreeData = data
        })
      }
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.parentName = ''
      this.editDialog = false
    },
    findMenuNode(id, menu) {
      if (menu.length === 0) {
        return
      }
      let node
      for (let i = 0; i < menu.length; i++) {
        const item = menu[i]
        if (item.id === id) {
          node = item
          break
        }
        if (item.children && item.children.length > 0) {
          node = this.findMenuNode(id, item.children)
          if (node && node.id === id) {
            break
          }
        }
      }

      return node
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.defaultItem.name = ''
      this.defaultItem.description = ''
      this.defaultItem.parentId = 0
      this.defaultItem.sortNum = 1
      this.parentName = ''
      this.editedItem = this.defaultItem
    },
    showDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
    toStockLog(item) {
      this.$router.push({ path: '/stockLog', query: { warehouseId: item.id } })
    },
  },
}
</script>
